@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'OpenSansLight';
  src: url('./assets/fonts/open_sans/OpenSans-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Inconstant';
  src: url('./assets/fonts/inconstant/InconstantVF.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/lato/Lato-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Lora';
  src: url('./assets/fonts/lora/LoraVF.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
